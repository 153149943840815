import productCaseStudies from "./productCaseStudiesData"
import aiChip from "../../static/images/trial/ai_chip.svg"
export default {
  name: "Applied Intelligence Applications",
  icon: aiChip,
  route: "/products",
  titleTxt:
    "Applications that help drive Industry Transformation across the value-chain",
  description:
    "Intelligent applications that incorporate industry, functional processes and engineering know-how into AI applications that can be deployed >10X faster than typical consulting engagements.",
  description_2:
    "Our AI applications drive top-line impact through intelligent optimization, generating higher yield and revenue while reducing maintenance and logistics costs. ",
  benefits: [
    {
      top: "Predict Failure",
      middle: "90",
      bottom: "days ahead",
    },
    {
      top: "Up to",
      middle: "5%",
      bottom: "margin improvement",
    },
    {
      top: "Additional",
      middle: "20%",
      bottom: "revenue opportunity",
    },

    {
      top: "Up to",
      middle: "10%",
      bottom: "improvement in yield",
    },
    {
      top: "Up to",
      middle: "200%",
      bottom: "improved accuracy in QC checks",
    },
  ],
  apps: [
    {
      name: "Vulcan Demand+",
      description:
        "Predict demand signals faster to identify opportunities for additional revenue generation. ",
      showPlus: true,
      icon: "/images/trial/AI_Demand.svg",
      img: "/images/products/demand.jpg",
      route: "products#demand",
      path: "demand",
      detailedDesc:
        "Vulcan Demand+ uses enterprise and external data sets enriched with SPOCK IoT AI on SPOCK Hyper-Data Platform, to sense demand leveraging a combination of signal processing and machine learning/deep learning for NLP, image and sensor data.",
      caseStudies: [productCaseStudies["demand_predictor"]],
    },
    {
      name: "Vulcan Margin+",
      icon: "/images/trial/AI_Margin.svg",
      img: "/images/products/margin.jpg",
      description:
        "Identify trading strategies that maximize margin considering demand and logistics.",
      detailedDesc:
        "Vulcan Margin+ uses a combination of AI approaches to identify demand and supply match and most profitable trades. We leverage deep learning models to predict supply with high accuracy and reinforcement learning and optimization to identify trading strategies that maximize margin considering demand and logistics  (e.g. product mix to produce, maximize margin for contracts and spot market etc.).",
      showPlus: true,
      route: "products#margin",
      path: "margin",
      caseStudies: [productCaseStudies["margin_optimization"]],
    },
    {
      name: "Vulcan Source+",
      img: "/images/products/source.jpg",
      icon: "/images/trial/AI_Source.svg",
      route: "products#source",
      path: "source",
      description:
        "Predict raw material needs to enable better prices on sourcing and optimize production plans based on product mix.",
      detailedDesc:
        "Vulcan Source+ utilizes demand indicators from Vulcan Margin+ along with plant performance/asset health and operating  data to predict raw material needs to enable better price on sourcing and optimize production plan based on product mix. This leverages a combination of AI techniques for forecasting and optimization.",
      showPlus: true,
      caseStudies: [
        productCaseStudies["forecast_sourcing_needs"],
        productCaseStudies["production_optimization"],
      ],
    },
    {
      name: "Vulcan Yield+",
      img: "/images/products/yield.jpg",
      icon: "/images/trial/AI_Yield.svg",
      description:
        "Predict yield and quality, and optimize control points along the manufacturing process to improve yield.",
      detailedDesc:
        "Vulcan Yield+ processes data from IoT sensors across units in a plant, raw material quality and ambient conditions to predict yield and quality, and optimize the manufacturing process setpoints to improve yield.",
      showPlus: true,
      route: "products#yield",
      path: "yield",
      caseStudies: [productCaseStudies["maximizing_yield"]],
    },
    {
      name: "Vulcan QC+",
      img: "/images/products/qc.jpg",
      icon: "/images/trial/AI_QC.svg",
      description:
        "Visually inspect, detect and classify production outputs anywhere and everywhere along the production process.",
      detailedDesc:
        "Vulcan QC+ app uses custom pipelines of computer vision and deep learning models to visually inspect, detect and classify production outputs. The models are optimized to be deployed anywhere and everywhere on the edge including mobile devices, equipments, assets etc.",
      showPlus: true,
      route: "products#qc",
      path: "qc",
      caseStudies: [productCaseStudies["automated_defect_detection"]],
    },

    {
      name: "Vulcan Health+",
      img: "/images/products/health.jpg",
      icon: "/images/trial/AI_Health.svg",
      description:
        "Detect performance and health anomalies for assets and processes, optimizing operating setpoints and maintenance activities.",
      detailedDesc:
        "Vulcan Health+ utilizes asset, process and operating environment data to detect performance, health anomalies for assets and processes and help optimize operating setpoints, maintenance and spare parts.",
      showPlus: true,
      route: "products#health",
      path: "health",
      caseStudies: [
        productCaseStudies["failure_prediction"],
        productCaseStudies["maintenance_predictions"],
      ],
    },
    {
      name: "Vulcan Fulfill+",
      icon: "/images/trial/AI_Fulfill.svg",
      img: "/images/products/fulfill.jpg",
      description:
        "Optimize warehouse locations, inventory and logistics, enhancing supply chain agility and responsiveness.",
      detailedDesc:
        "Vulcan Fulfill+ focuses on leveraging optimization techniques to ensure that supply chain and logistics is agile and responsive to deliver the goods to clients on time and make it cost effective.",
      showPlus: true,
      route: "products#fulfill",
      path: "fulfill",
      caseStudies: [productCaseStudies["optimize_warehouse"]],
    },
    {
      name: "Vulcan Transport+",
      img: "/images/products/transportation.jpg",
      icon: "/images/trial/AI_Transport.svg",
      description:
        "Increase visibility and enable optimization of transportation assets by detecting performance and health anomalies at the individual asset level.",
      detailedDesc:
        "Vulcan Transport+ uses fleet logistics, asset sensor data and operating environment data to detect performance and/or health anomalies at individual asset level for proactive intervention and identify key operating environments (e.g. road/rail conditions, load, driver behavior etc.) that impact asset availability & maintenance costs.",
      showPlus: true,
      route: "products#transport",
      path: "transport",
      caseStudies: [productCaseStudies["predicting_asset_availability"]],
    },
  ],
}
