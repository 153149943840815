import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import OrbImg from "../../static/images/robot.jpg"
import PageHeader from "../partialPages/PageHeader"
import { Row, Col, Container } from "react-bootstrap"
import productData from "../data/productData"
const page = productData

const Products = () => {
  const getAppDiv = app => {
    return (
      <div
        style={{
          height: "100%",
        }}
      >
        <div className="w-100 d-none d-xl-block p-2 ">
          {app.img ? (
            <img
              src={app.img}
              alt={app.name}
              className="w-100"
              style={{ height: "175px" }}
            />
          ) : null}
        </div>
        <div className="w-100 d-block d-xl-none  p-2 ">
          {app.img ? (
            <img
              src={app.img}
              alt={app.name}
              className="w-100"
              style={{ height: "200px" }}
            />
          ) : null}
        </div>
        <div className="p-2">
          <h4
            className="mb-0 text-bold"
            style={{ fontSize: "20px", lineHeight: "40px" }}
          >
            {app.name}
          </h4>
        </div>

        <p
          className="pb-2 px-2"
          style={{ fontSize: "1rem", lineHeight: "25px" }}
        >
          {app.description}
        </p>
      </div>
    )
  }
  return (
    <Layout pageInfo={{ pageName: "products" }}>
      <SEO title={page.name} description={page.description} />
      <PageHeader
        bgImg={OrbImg}
        pgTitle={page.name}
        pgDesc={page.description}
        pgDesc2={page.description_2}
        pClass="text-left"
        pStyle={{ marginLeft: 80, marginRight: 80 }}
      ></PageHeader>
      <div className="bg-white">
        <Container>
          <section
            id="platform-boxes"
            style={{ position: "relative" }}
            className="pt-5 pb-4"
          >
            <div className="d-none d-lg-block">
              <Row>
                {page.apps &&
                  page.apps.map((app, appIndex) => (
                    <Col
                      md="6"
                      lg="4"
                      xl="3"
                      key={appIndex}
                      id={app.path}
                      style={{ marginBottom: "0.5rem", paddingRight: "30px" }}
                      className=" pl-0"
                    >
                      {getAppDiv(app)}
                    </Col>
                  ))}
              </Row>{" "}
            </div>{" "}
            <div className="d-block d-lg-none">
              <Row>
                {page.apps &&
                  page.apps.map((app, appIndex) => (
                    <Col
                      md="6"
                      lg="4"
                      xl="3"
                      key={appIndex}
                      id={app.path}
                      style={{ marginBottom: "0.5rem" }}
                    >
                      {getAppDiv(app)}
                    </Col>
                  ))}
              </Row>{" "}
            </div>{" "}
          </section>
          <div
            style={{ marginLeft: "-8px", marginRight: "24px" }}
            className="d-none d-lg-block"
          >
            <hr />
          </div>
          <div className="d-block d-lg-none">
            <hr />
          </div>
          <section id="benefits">
            <div id="fiveColGrid" className="py-5 d-none d-md-block">
              <div className="row">
                {productData.benefits.map((b, i) => (
                  <div className="col" key={i}>
                    <p className="top"> {b.top}</p>
                    <p className="middle"> {b.middle}</p>
                    <p className="bottom"> {b.bottom}</p>
                  </div>
                ))}
              </div>
            </div>
            <div className="d-block d-md-none">
              <div className="pb-5">
                {productData.benefits.map((b, i) => (
                  <div className="w-100 text-center py-5" key={i}>
                    <p className="top"> {b.top}</p>
                    <p className="middle"> {b.middle}</p>
                    <p className="bottom"> {b.bottom}</p>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </Container>
      </div>{" "}
    </Layout>
  )
}

export default Products
