import retailDemandImg from "../images/spock/case_studies/retail_demand_prediction.png"
import commodityImg from "../images/spock/case_studies/commodity_demand.png"
import demandProductionImg from "../images/spock/case_studies/demand_production.png"
import forwardPriceImg from "../images/spock/case_studies/forward_price_forecast.png"
import forecastSourcingImg from "../images/spock/case_studies/forecast_sourcing.png"
import forecastRawMaterialImg from "../images/spock/case_studies/forecasting_raw_material.png"
import onglImg from "../images/spock/case_studies/oil_gas_yield.png"
import maxPImg from "../images/spock/case_studies/max_production.png"
import autoQCImg from "../images/spock/case_studies/automated_qc.png"
import sgQCImg from "../images/spock/case_studies/smart_grader.png"
import failurePredictionImg from "../images/spock/case_studies/failure_prediction.png"
import maintainencePredictionImg from "../images/spock/case_studies/maintenance_prediction.png"
import warehouseLocationImg from "../images/spock/case_studies/warehouse_location.png"
import assetAvailbilityImg from "../images/spock/case_studies/asset_availability.png"

export default {
  demand_predictor: {
    name: "Demand Predictor",
    showPlus: true,
    descriptions: [
      "Predict demand signals faster by combining different data sources including internal sales, AIS/shipping data, GIS information and remote sensing.",
      "AI on satellite image data can help an F&B client identify up to ~20% additional revenue opportunity by identifying demand indicators by region such as residential vs., commercial density, types of housing (e.g. swimming pools etc.). ",
      "Predicting commodity demand based on AIS fleet data, ports visited, travel time, macro-indicators etc. Combining remote sensing with distribution data to predict demand and identify white-space.",
    ],
    images: [
      {
        src: retailDemandImg,
        title: "Retail Demand Prediction",
      },
      {
        src: commodityImg,
        title: "Predicting commodity demand ",
      },
    ],
  },
  margin_optimization: {
    name: "Margin Optimization",
    showPlus: true,
    descriptions: [
      "Combines accurate production forecast with forward price curves, contracts and logistics to optimize product mix and trading strategies.",
      "For clients in the natural resources industry we have leveraged a combination of upstream production forecast with forward prices for products and delivery plans to improve  margin by ~5%.",
    ],
    images: [
      {
        src: forwardPriceImg,
        title: "Forward Price Forecast",
      },
      {
        src: demandProductionImg,
        title: "Demand and Production",
      },
    ],
  },
  forecast_sourcing_needs: {
    name: "Forecast Sourcing Needs",
    showPlus: true,
    descriptions: [
      "AI models used to identify volume needed and select source that provides the right quality and grade of raw material",
    ],
    images: [
      {
        src: forecastSourcingImg,
        title: "Production Planning Module to Optimize production plan",
      },
    ],
  },
  production_optimization: {
    name: "Production Optimization",
    showPlus: true,
    descriptions: [
      "We leveraged a combination of product mix, raw material and asset health to optimize the production plan for changes between multiple grades enabling higher throughput and margin.",
    ],
    images: [
      {
        src: forecastRawMaterialImg,
        title: "Forecasting raw material volume and quality for sourcing",
      },
    ],
  },
  maximizing_yield: {
    name: "Maximize Yield",
    descriptions: [
      "Ability to predict yield under certain combination of conditions and identify process adjustments can help improve yield significantly and reduce raw material cost e.g. in bleaching earth optimization for refinery. ",
      "Yield is maximized based on adapting golden recipe to raw material quality (~5-10% improvement in yield).",
    ],
    images: [
      {
        src: onglImg,
        title:
          "Optimization of plant setting to maximize yield in Oil & Gas, Mining, Manufacturing etc",
      },
      {
        src: maxPImg,
        title:
          "Maximize Production by using real time sensors and AI algorithms to predict throughput",
      },
    ],
  },
  automated_defect_detection: {
    name: "Automated detection of defects",
    descriptions: [
      "Vulcan Vision AI is being used in a variety of settings such as automated detection of defects in manufacturing, checking raw material quality to mills, foreign material in bottles and packaged goods, in biotech to automate the inspection of genetic material for defects etc. ",
      "They enable automated and full inspection rather than sample-based checking.",
      "For one of our clients we improved accuracy of grading of product by 2X compared to manual QC.",
    ],
    images: [
      {
        src: autoQCImg,
        title:
          "Combining Vision AI and Robotics to detect and segregate based on quality of product",
      },
      {
        src: sgQCImg,
        title:
          "In-situ detection of issues (e.g. quality of manufactured goods, harvest quality etc.) served on mobile",
      },
    ],
  },
  failure_prediction: {
    name: "Failure Prediction",
    descriptions: [
      "Combining multiple data sources sensor data, event logs, service data in AI model to predict failures 90 days ahead.",
    ],
    images: [
      {
        src: failurePredictionImg,
        title:
          "AI/ML based optimization of plant maintenance of Oil & Gas, Mining, Manufacturing etc",
      },
    ],
  },
  maintenance_predictions: {
    name: "Maintenance Prediction",
    descriptions: [
      "Predicting optimal window for major maintenance considering impact to throughput and quality with cost of downtime for assets such as boilers, heat exchangers etc.",
    ],
    images: [
      {
        src: maintainencePredictionImg,
        title: "Static Equipment Health (Fouling, Corrosion etc.)",
      },
    ],
  },
  optimize_warehouse: {
    name: "Optimize warehouse locations, inventory and logistics",
    descriptions: [
      "Ability to optimize the inventory, warehouse locations and logistics (type of vehicles and route) can significantly improve both efficiency of supply chain and reduce costs.",
      "We have leveraged optimization algorithms such as MIP, heuristic techniques etc. to help optimize supply chain leveraging demand and supply forecasts.",
    ],
    images: [
      {
        src: warehouseLocationImg,
        title:
          "Optimizing warehouse locations, inventory and logistics to fulfill orders at the lowest cost under constraints",
      },
    ],
  },
  predicting_asset_availability: {
    name: "Predicting Asset Health",
    descriptions: [
      "Predicting health of asset components such as wagon wheels (for recondition),  shovel winding health etc. based asset and environment data can significantly reduce disruption due to prime mover breakdowns.",
      "Optimize shipping fleet based on contracts, volume to be fulfilled, ship lease, operating cost, optimizing boil-off etc. to reduce costs and improve deliveries.",
    ],
    images: [
      {
        src: assetAvailbilityImg,
        title:
          "Predicting asset availability and optimal utilization of fleet based on asset sensor, operations data, environment conditions etc.",
      },
    ],
  },
}
